import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import UserManagement from './components/UserManagement';
import ProfileManagement from './components/ProfileManagement';
import CompanyManagement from './components/CompanyManagement';
import JobPostingManagement from './components/JobPostingManagement';
import ApplicationManagement from './components/ApplicationManagement';

export default function AdminDashboard() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const TabPanel = ({ children, value, index }) => (
    <div hidden={value !== index}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );

  return (
    <Container maxWidth="xl" sx={{ mt: 3, mb: 4 }}>
      <Typography class="h2 text-white" gutterBottom>
        Admin Dashboard
      </Typography>

      <Paper sx={{ width: '100%', mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Users" />
          <Tab label="Profiles" />
          <Tab label="Companies" />
          <Tab label="Job Postings" />
          <Tab label="Applications" />
        </Tabs>
      </Paper>

      <TabPanel value={activeTab} index={0}>
        <UserManagement />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <ProfileManagement />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <CompanyManagement />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <JobPostingManagement />
      </TabPanel>
      <TabPanel value={activeTab} index={4}>
        <ApplicationManagement />
      </TabPanel>
    </Container>
  );
} 