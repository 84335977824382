import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Grid,
  Autocomplete,
  Chip,
  Typography,
  Divider,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { userApi } from '../../../api/userApi';

const initialState = {
  userId: '',
  name: '',
  email: '',
  location: '',
  bio: '',
  currentRole: '',
  targetCompanies: [],
  preferredRoles: [],
  interests: [],
  hobbies: [],
  skills: [],
  education: [],
  experience: [],
  profileCompleted: false,
  jobSearchStatus: 'Actively Looking',
};

const skillLevels = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];
const jobStatusOptions = ['Actively Looking', 'Open to Opportunities', 'Not Looking'];
const workTypeOptions = ['Full-time', 'Part-time', 'Contract', 'Internship'];

export default function ProfileForm({ profile, onSave, isCreating, loading }) {
  const [formData, setFormData] = useState(initialState);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    if (profile && !isCreating) {
      setFormData({
        ...initialState,
        ...profile,
        // Ensure arrays are set even if they don't exist in the profile
        skills: profile.skills || [],
        targetCompanies: profile.targetCompanies || [],
        preferredRoles: profile.preferredRoles || [],
        interests: profile.interests || [],
        hobbies: profile.hobbies || [],
        education: profile.education || [],
        experience: profile.experience || [],
      });
    } else {
      setFormData(initialState);
      fetchAvailableUsers();
    }
  }, [profile, isCreating]);

  const fetchAvailableUsers = async () => {
    try {
      setLoadingUsers(true);
      const response = await userApi.getUsers();
      setAvailableUsers(response.data || []);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUserSelect = (event, newValue) => {
    if (newValue) {
      setFormData({
        ...formData,
        userId: newValue._id,
        name: newValue.name || '',
        email: newValue.email || '',
      });
    }
  };

  const handleArrayChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSkillChange = (index, field, value) => {
    const updatedSkills = [...formData.skills];
    updatedSkills[index] = { ...updatedSkills[index], [field]: value };
    setFormData({ ...formData, skills: updatedSkills });
  };

  const addSkill = () => {
    setFormData({
      ...formData,
      skills: [...formData.skills, { name: '', level: 'Beginner', endorsements: 0 }],
    });
  };

  const removeSkill = (index) => {
    const skills = [...formData.skills];
    skills.splice(index, 1);
    setFormData({ ...formData, skills });
  };

  const addEducation = () => {
    setFormData({
      ...formData,
      education: [
        ...formData.education,
        {
          institution: '',
          level: '',
          field: '',
          graduationYear: '',
          current: false,
        },
      ],
    });
  };

  const handleEducationChange = (index, field, value) => {
    const updatedEducation = [...formData.education];
    updatedEducation[index] = { ...updatedEducation[index], [field]: value };
    setFormData({ ...formData, education: updatedEducation });
  };

  const removeEducation = (index) => {
    const education = [...formData.education];
    education.splice(index, 1);
    setFormData({ ...formData, education });
  };

  const addExperience = () => {
    setFormData({
      ...formData,
      experience: [
        ...formData.experience,
        {
          title: '',
          company: '',
          location: '',
          type: 'Full-time',
          startDate: '',
          endDate: '',
          current: false,
          description: '',
          skills: [],
        },
      ],
    });
  };

  const handleExperienceChange = (index, field, value) => {
    const updatedExperience = [...formData.experience];
    updatedExperience[index] = { ...updatedExperience[index], [field]: value };
    setFormData({ ...formData, experience: updatedExperience });
  };

  const removeExperience = (index) => {
    const experience = [...formData.experience];
    experience.splice(index, 1);
    setFormData({ ...formData, experience });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      {/* User Selection (for creation only) */}
      {isCreating && (
        <Box mb={3}>
          <Autocomplete
            id="userId"
            options={availableUsers}
            loading={loadingUsers}
            getOptionLabel={(option) => `${option.name} (${option.email})`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select User"
                required
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            onChange={handleUserSelect}
          />
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
            disabled={!isCreating && !formData.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="email"
            label="Email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
            disabled={!isCreating && !formData.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="location"
            label="Location"
            value={formData.location || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="currentRole"
            label="Current Role"
            value={formData.currentRole || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="bio"
            label="Bio"
            value={formData.bio || ''}
            onChange={handleChange}
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Job Search Status</InputLabel>
            <Select
              name="jobSearchStatus"
              value={formData.jobSearchStatus || 'Actively Looking'}
              onChange={handleChange}
              label="Job Search Status"
            >
              {jobStatusOptions.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Profile Completed</InputLabel>
            <Select
              name="profileCompleted"
              value={formData.profileCompleted || false}
              onChange={(e) => handleChange({
                target: { name: 'profileCompleted', value: e.target.value === 'true' }
              })}
              label="Profile Completed"
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      {/* Interests & Hobbies */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Interests & Hobbies</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.interests || []}
                onChange={(e, newValue) => handleArrayChange('interests', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Interests"
                    placeholder="Add interests"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.hobbies || []}
                onChange={(e, newValue) => handleArrayChange('hobbies', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Hobbies"
                    placeholder="Add hobbies"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Skills */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Skills</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2}>
            <Button
              startIcon={<AddIcon />}
              onClick={addSkill}
              variant="outlined"
              size="small"
            >
              Add Skill
            </Button>
          </Box>
          {formData.skills.map((skill, index) => (
            <Grid container spacing={2} key={index} alignItems="center" sx={{ mb: 2 }}>
              <Grid item xs={5}>
                <TextField
                  label="Skill Name"
                  value={skill.name || ''}
                  onChange={(e) => handleSkillChange(index, 'name', e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <InputLabel>Level</InputLabel>
                  <Select
                    value={skill.level || 'Beginner'}
                    onChange={(e) => handleSkillChange(index, 'level', e.target.value)}
                    label="Level"
                  >
                    {skillLevels.map((level) => (
                      <MenuItem key={level} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => removeSkill(index)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Job Preferences */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Job Preferences</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.preferredRoles || []}
                onChange={(e, newValue) => handleArrayChange('preferredRoles', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Preferred Roles"
                    placeholder="Add roles"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.targetCompanies || []}
                onChange={(e, newValue) => handleArrayChange('targetCompanies', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Target Companies"
                    placeholder="Add companies"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Education */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Education</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2}>
            <Button
              startIcon={<AddIcon />}
              onClick={addEducation}
              variant="outlined"
              size="small"
            >
              Add Education
            </Button>
          </Box>
          {formData.education.map((edu, index) => (
            <Box key={index} sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography variant="subtitle1">Education #{index + 1}</Typography>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'right' }}>
                  <IconButton onClick={() => removeEducation(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Institution"
                    value={edu.institution || ''}
                    onChange={(e) => handleEducationChange(index, 'institution', e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Level/Degree"
                    value={edu.level || ''}
                    onChange={(e) => handleEducationChange(index, 'level', e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Field of Study"
                    value={edu.field || ''}
                    onChange={(e) => handleEducationChange(index, 'field', e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Graduation Year"
                    value={edu.graduationYear || ''}
                    onChange={(e) => handleEducationChange(index, 'graduationYear', e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Experience */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Experience</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2}>
            <Button
              startIcon={<AddIcon />}
              onClick={addExperience}
              variant="outlined"
              size="small"
            >
              Add Experience
            </Button>
          </Box>
          {formData.experience.map((exp, index) => (
            <Box key={index} sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography variant="subtitle1">Experience #{index + 1}</Typography>
                </Grid>
                <Grid item xs={2} sx={{ textAlign: 'right' }}>
                  <IconButton onClick={() => removeExperience(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Title"
                    value={exp.title || ''}
                    onChange={(e) => handleExperienceChange(index, 'title', e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company"
                    value={exp.company || ''}
                    onChange={(e) => handleExperienceChange(index, 'company', e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Location"
                    value={exp.location || ''}
                    onChange={(e) => handleExperienceChange(index, 'location', e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={exp.type || 'Full-time'}
                      onChange={(e) => handleExperienceChange(index, 'type', e.target.value)}
                      label="Type"
                    >
                      {workTypeOptions.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    value={exp.description || ''}
                    onChange={(e) => handleExperienceChange(index, 'description', e.target.value)}
                    fullWidth
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => setFormData(initialState)} disabled={loading}>
          Reset
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : isCreating ? 'Create' : 'Update'}
        </Button>
      </Box>
    </Box>
  );
} 