import { ENDPOINTS } from '../config';
import axiosInstance from './axiosConfig';

export const applicationApi = {
  getAllApplications: async () => {
    try {
      const response = await axiosInstance.get(`${ENDPOINTS.APPLICATIONS.BASE}`);
      return response;
    } catch (error) {
      console.error('Error fetching applications:', error);
      throw error.response?.data || error;
    }
  },

  getApplicationById: async (applicationId) => {
    try {
      if (!applicationId) {
        throw new Error('Application ID is required');
      }
      const response = await axiosInstance.get(`${ENDPOINTS.APPLICATIONS.BASE}/${applicationId}`);
      return response;
    } catch (error) {
      console.error('Error fetching application details:', error);
      throw error.response?.data || error;
    }
  },

  getUserApplications: async (userId) => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
      const response = await axiosInstance.get(`${ENDPOINTS.APPLICATIONS.BASE}/user/${userId}`);
      return response;
    } catch (error) {
      console.error('Error fetching user applications:', error);
      throw error.response?.data || error;
    }
  },

  createApplication: async (applicationData) => {
    try {
      if (!applicationData) {
        throw new Error('Application data is required');
      }
      const response = await axiosInstance.post(`${ENDPOINTS.APPLICATIONS.BASE}`, applicationData);
      return response;
    } catch (error) {
      console.error('Error creating application:', error);
      throw error.response?.data || error;
    }
  },

  updateApplication: async (applicationId, applicationData) => {
    try {
      if (!applicationId) {
        throw new Error('Application ID is required');
      }
      const response = await axiosInstance.put(
        `${ENDPOINTS.APPLICATIONS.BASE}/${applicationId}`, 
        applicationData
      );
      return response;
    } catch (error) {
      console.error('Error updating application:', error);
      throw error.response?.data || error;
    }
  },

  deleteApplication: async (applicationId) => {
    try {
      if (!applicationId) {
        throw new Error('Application ID is required');
      }
      const response = await axiosInstance.delete(`${ENDPOINTS.APPLICATIONS.BASE}/${applicationId}`);
      return response;
    } catch (error) {
      console.error('Error deleting application:', error);
      throw error.response?.data || error;
    }
  },

  updateApplicationStatus: async (applicationId, status) => {
    try {
      if (!applicationId) {
        throw new Error('Application ID is required');
      }
      const response = await axiosInstance.patch(
        `${ENDPOINTS.APPLICATIONS.BASE}/${applicationId}/status`, 
        { status }
      );
      return response;
    } catch (error) {
      console.error('Error updating application status:', error);
      throw error.response?.data || error;
    }
  },

  addApplicationInterview: async (applicationId, interviewData) => {
    try {
      if (!applicationId) {
        throw new Error('Application ID is required');
      }
      const response = await axiosInstance.post(
        `${ENDPOINTS.APPLICATIONS.BASE}/${applicationId}/interviews`, 
        interviewData
      );
      return response;
    } catch (error) {
      console.error('Error adding interview to application:', error);
      throw error.response?.data || error;
    }
  },

  trackApplicationTimeline: async (applicationId, timelineEntry) => {
    try {
      if (!applicationId) {
        throw new Error('Application ID is required');
      }
      const response = await axiosInstance.post(
        `${ENDPOINTS.APPLICATIONS.BASE}/${applicationId}/timeline`, 
        timelineEntry
      );
      return response;
    } catch (error) {
      console.error('Error updating application timeline:', error);
      throw error.response?.data || error;
    }
  }
}; 