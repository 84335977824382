import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Snackbar, 
  Alert,
  CircularProgress,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import DataTable from './common/DataTable';
import { applicationApi } from '../../../api/applicationApi';
import AddIcon from '@mui/icons-material/Add';

const applicationStatusOptions = [
  'Draft', 
  'Submitted', 
  'Under Review', 
  'Interview', 
  'Offer', 
  'Rejected',
  'Withdrawn'
];

export default function ApplicationManagement() {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [formData, setFormData] = useState({
    userId: '',
    companyId: '',
    jobPostingId: '',
    position: '',
    companyName: '',
    status: 'Draft',
    notes: '',
  });
  const [notification, setNotification] = useState({ 
    open: false, 
    message: '', 
    severity: 'success' 
  });
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    try {
      setLoading(true);
      const response = await applicationApi.getAllApplications();
      // Handle both array format and nested data format
      const applicationsData = Array.isArray(response.data) ? response.data : 
                              (response.data?.data ? response.data.data : []);
      setApplications(applicationsData);
    } catch (error) {
      console.error('Error fetching applications:', error);
      setApplications([]);
      showNotification('Failed to fetch applications', 'error');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { 
      id: '_id', 
      label: 'ID',
      render: (row) => row._id.substring(0, 8) + '...'
    },
    { 
      id: 'userId', 
      label: 'User',
      render: (row) => {
        const userId = typeof row.userId === 'object' ? row.userId.$oid : row.userId;
        return userId ? userId.substring(0, 8) + '...' : 'N/A';
      }
    },
    { id: 'position', label: 'Position' },
    { id: 'companyName', label: 'Company' },
    { 
      id: 'status', 
      label: 'Status',
      render: (row) => (
        <Chip 
          label={row.status} 
          color={getStatusColor(row.status)}
          size="small"
        />
      )
    },
    { 
      id: 'submissionDate', 
      label: 'Submitted',
      render: (row) => new Date(row.submissionDate).toLocaleDateString()
    },
  ];

  const getStatusColor = (status) => {
    switch(status) {
      case 'Draft': return 'default';
      case 'Submitted': return 'primary';
      case 'Under Review': return 'info';
      case 'Interview': return 'warning';
      case 'Offer': return 'success';
      case 'Rejected': return 'error';
      case 'Withdrawn': return 'secondary';
      default: return 'default';
    }
  };

  const handleEdit = (application) => {
    setSelectedApplication(application);
    setFormData({
      userId: application.userId,
      companyId: application.companyId,
      jobPostingId: application.jobPostingId,
      position: application.position,
      companyName: application.companyName,
      status: application.status,
      notes: application.notes || '',
    });
    setIsCreating(false);
    setOpenDialog(true);
  };

  const handleCreate = () => {
    setSelectedApplication(null);
    setFormData({
      userId: '',
      companyId: '',
      jobPostingId: '',
      position: '',
      companyName: '',
      status: 'Draft',
      notes: '',
    });
    setIsCreating(true);
    setOpenDialog(true);
  };

  const handleDelete = async (application) => {
    if (window.confirm('Are you sure you want to delete this application?')) {
      try {
        await applicationApi.deleteApplication(application._id);
        showNotification('Application deleted successfully', 'success');
        fetchApplications();
      } catch (error) {
        console.error('Error deleting application:', error);
        showNotification('Failed to delete application', 'error');
      }
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (isCreating) {
        // Create new application
        await applicationApi.createApplication(formData);
        showNotification('Application created successfully', 'success');
      } else {
        // Update existing application
        await applicationApi.updateApplication(selectedApplication._id, formData);
        showNotification('Application updated successfully', 'success');
      }
      setOpenDialog(false);
      fetchApplications();
    } catch (error) {
      console.error('Error saving application:', error);
      showNotification('Failed to save application', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const showNotification = (message, severity) => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Box sx={{ color: 'white' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Application Management</Typography>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create Application
        </Button>
      </Box>
      
      {loading && !openDialog ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataTable
          data={applications}
          columns={columns}
          onEdit={handleEdit}
          onDelete={handleDelete}
          loading={loading}
        />
      )}

      {/* Application Form Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {isCreating ? 'Create Application' : 'Edit Application'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="userId"
                label="User ID"
                value={formData.userId}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="companyId"
                label="Company ID"
                value={formData.companyId}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="jobPostingId"
                label="Job Posting ID"
                value={formData.jobPostingId}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="position"
                label="Position"
                value={formData.position}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="companyName"
                label="Company Name"
                value={formData.companyName}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  label="Status"
                >
                  {applicationStatusOptions.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="notes"
                label="Notes"
                value={formData.notes}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleSave} 
            variant="contained" 
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
} 