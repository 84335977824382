import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container, Paper, Typography, Grid, Avatar, Button, TextField, Box, 
  Divider, Chip, List, ListItem, ListItemText, Card, CardContent
} from '@mui/material';
import { Edit, Save, Work, School, Stars, Add } from '@mui/icons-material';
import { profileApi } from '../api/profileApi';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';
import { updateUser } from '../library/store/authentication';

export default function UserProfile() {
  const userData = useSelector((state) => state?.authentication?.value?.loginData) || {};
  const dispatch = useDispatch();
  
  console.log('UserProfile - userData from Redux:', userData);
  
  // Extract userId, checking both id and _id fields
  const userId = userData?._id || userData?.id;
  
  console.log('UserProfile - determined userId:', userId);
  
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState(null);
  const [formData, setFormData] = useState({
    name: userData?.name || '',
    email: userData?.email || '',
    phone: userData?.phone || '',
    location: userData?.location || '',
    bio: userData?.bio || '',
  });
  const [strengthDialog, setStrengthDialog] = useState(false);
  const [strengthDetails, setStrengthDetails] = useState({ strength: 0, improvements: [] });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [newSkill, setNewSkill] = useState({ name: '', level: 'Intermediate' });
  const [skillDialogOpen, setSkillDialogOpen] = useState(false);
  const isMounted = useRef(true);

  // Set up isMounted ref for cleanup
  useEffect(() => {
    return () => {
      isMounted.current = false;
      setProfile(null);
      setFormData({});
    };
  }, []);

  // Initial profile fetch
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setIsLoading(true);
        console.log('Fetching profile for userId:', userId);
        
        if (!userId) {
          console.log('No user ID available for profile fetch');
          if (isMounted.current) setIsLoading(false);
          return;
        }

        const response = await profileApi.getProfile(userId);
        console.log('Profile response:', response);
        
        if (!isMounted.current) return;

        if (response?.data) {
          // Update formData with all profile fields 
          setFormData({
            name: response.data.name || userData?.name || '',
            email: response.data.email || userData?.email || '',
            phone: response.data.phone || '',
            location: response.data.location || '',
            bio: response.data.bio || '',
          });

          // Set full profile object
          setProfile(response.data);
          console.log('Profile state updated with:', response.data);
          
          // If profile data exists but user data is incomplete, update user data
          if (userData && (!userData.name || !userData.email) && (response.data.name || response.data.email)) {
            dispatch(updateUser({
              userId: userId,
              userData: {
                name: response.data.name || userData.name,
                email: response.data.email || userData.email
              }
            }));
          }
        }
        else {
          console.log('No profile found or empty response');
        }
      } catch (error) {
        if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
          console.error('Error fetching profile:', error);
        }
      } finally {
        if (isMounted.current) setIsLoading(false);
      }
    };
    
    fetchProfile();
  }, [userId, dispatch, userData]);

  // Periodic profile refresh
  useEffect(() => {
    if (!userId) return;

    const refreshInterval = setInterval(async () => {
      if (!isMounted.current) return;
      
      try {
        const response = await profileApi.getProfile(userId);
        if (response?.data && isMounted.current) {
          setProfile(response.data);
        }
      } catch (error) {
        if (error.name !== 'CanceledError' && error.name !== 'AbortError') {
          console.error('Error refreshing profile:', error);
        }
      }
    }, 30000);

    return () => clearInterval(refreshInterval);
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const updatedProfile = {
        ...profile,
        ...formData,
        updatedAt: new Date()
      };

      console.log('Sending profile update:', updatedProfile);

      const updateResponse = await profileApi.updateProfile(userId, updatedProfile);

      if (updateResponse?.data) {
        setProfile(updateResponse.data);
        
        setFormData({
          name: updateResponse.data.name || '',
          email: updateResponse.data.email || '',
          phone: updateResponse.data.phone || '',
          location: updateResponse.data.location || '',
          bio: updateResponse.data.bio || '',
        });
        try {
          const response = await profileApi.getProfile(userId);
          if (response?.data) {
            setProfile(response.data);
          }
          console.log('Profile updated:', response.data);
        } catch (error) {
          console.error('Error refreshing profile:', error);
        }
        const strengthResponse = await profileApi.getProfileStrength(userId);
        setStrengthDetails(strengthResponse.data);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setIsSubmitting(false);
      setIsEditing(false);
    }
  };

  const capitalizeWords = (text) => {
    if (!text) return '';
    return text.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const handleStrengthClick = async () => {
    try {
      const response = await profileApi.getProfileStrength(userId);
      setStrengthDetails(response.data);
      setStrengthDialog(true);
    } catch (error) {
      console.error('Error fetching profile strength:', error);
    }
  };

  const handleAddSkill = async () => {
    if (!newSkill.name.trim()) return;
    
    try {
      setIsSubmitting(true);
      const updatedSkills = [...(profile?.skills || []), newSkill];
      const updatedProfile = {
        ...profile,
        skills: updatedSkills,
      };
      
      const updateResponse = await profileApi.updateProfile(userId, updatedProfile);
      
      if (updateResponse?.data) {
        setProfile(updateResponse.data);
        setNewSkill({ name: '', level: 'Intermediate' });
        setSkillDialogOpen(false);
      }
    } catch (error) {
      console.error('Error adding skill:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteSkill = async (index) => {
    try {
      setIsSubmitting(true);
      const updatedSkills = [...profile.skills];
      updatedSkills.splice(index, 1);
      
      const updatedProfile = {
        ...profile,
        skills: updatedSkills,
      };
      
      const updateResponse = await profileApi.updateProfile(userId, updatedProfile);
      
      if (updateResponse?.data) {
        setProfile(updateResponse.data);
      }
    } catch (error) {
      console.error('Error deleting skill:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const ProfileStrengthDialog = () => (
    <Dialog 
      open={strengthDialog} 
      onClose={() => setStrengthDialog(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Profile Strength Analysis</DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center', my: 2 }}>
          <Typography variant="h3" color="primary">
            {strengthDetails.strength}%
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Profile Strength
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Areas for Improvement
        </Typography>
        <List>
          {strengthDetails.improvements.map((improvement, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <ArrowForwardIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={improvement} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setStrengthDialog(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  const SkillDialog = () => (
    <Dialog open={skillDialogOpen} onClose={() => setSkillDialogOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Skill</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Skill Name"
          value={newSkill.name}
          onChange={(e) => setNewSkill({...newSkill, name: e.target.value})}
          margin="normal"
        />
        <Box mt={2}>
          <Typography gutterBottom>Skill Level</Typography>
          <select
            value={newSkill.level}
            onChange={(e) => setNewSkill({...newSkill, level: e.target.value})}
            style={{ padding: '8px', width: '100%' }}
          >
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
            <option value="Expert">Expert</option>
          </select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setSkillDialogOpen(false)}>Cancel</Button>
        <Button 
          onClick={handleAddSkill} 
          variant="contained" 
          disabled={isSubmitting || !newSkill.name.trim()}
        >
          {isSubmitting ? 'Adding...' : 'Add Skill'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Prepare display values with fallbacks
  const displayName = profile?.name || userData?.name || 'User Name';
  const displayEmail = profile?.email || userData?.email || '';
  const displayPhone = profile?.phone || '';
  const displayLocation = profile?.location || '';
  const displayBio = profile?.bio || '';

  if (isLoading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ ml: 2 }}>Loading profile...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Profile
          </Typography>
          <Button
            variant="contained"
            startIcon={isEditing ? (isSubmitting ? <CircularProgress size={20} color="inherit" /> : <Save />) : <Edit />}
            onClick={() => isEditing ? handleSubmit() : setIsEditing(true)}
            disabled={isSubmitting}
          >
            {isEditing ? (isSubmitting ? 'Saving...' : 'Save Changes') : 'Edit Profile'}
          </Button>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
            <Avatar
              sx={{
                width: 200,
                height: 200,
                mx: 'auto',
                mb: 2,
                bgcolor: 'primary.dark',
                border: '4px solid',
                borderColor: 'primary.main',
              }}
            >
              {displayName?.[0] || 'U'}
            </Avatar>
            {!isEditing && (
              <Typography variant="h5" gutterBottom>
                {displayName}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={8}>
            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="name"
                    value={isEditing ? formData.name : displayName}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={isEditing ? formData.email : displayEmail}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={isEditing ? formData.phone : displayPhone}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Location"
                    name="location"
                    value={isEditing ? formData.location : displayLocation}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Bio"
                    name="bio"
                    value={isEditing ? formData.bio : displayBio}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid item xs={12}>
            <Card sx={{ mt: 3 }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">
                    <Stars sx={{ mr: 1, verticalAlign: 'middle' }} />
                    Skills
                  </Typography>
                  <Button 
                    variant="outlined" 
                    startIcon={<Add />}
                    onClick={() => setSkillDialogOpen(true)}
                    size="small"
                  >
                    Add Skill
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {profile?.skills?.map((skill, index) => (
                    <Chip
                      key={index}
                      label={`${capitalizeWords(skill.name)} (${capitalizeWords(skill.level)})`}
                      color="primary"
                      variant="outlined"
                      onDelete={() => handleDeleteSkill(index)}
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  <Work sx={{ mr: 1, verticalAlign: 'middle' }} />
                  Work Experience
                </Typography>
                <List>
                  {profile?.experience?.map((exp, index) => (
                    <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {exp.title} at {exp.company}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {exp.startDate} - {exp.current ? 'Present' : exp.endDate}
                      </Typography>
                      <Typography variant="body2">{exp.description}</Typography>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  <School sx={{ mr: 1, verticalAlign: 'middle' }} />
                  Education
                </Typography>
                <List>
                  {profile?.education?.map((edu, index) => (
                    <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {edu.level} in {edu.field}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {edu.institution} - {edu.graduationYear}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Interests</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {profile?.interests?.map((interest, index) => (
                    <Chip key={index} label={capitalizeWords(interest)} />
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Hobbies</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {profile?.hobbies?.map((hobby, index) => (
                    <Chip key={index} label={capitalizeWords(hobby)} />
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Career Preferences</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Target Companies</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {profile?.targetCompanies?.map((company, index) => (
                        <Chip key={index} label={capitalizeWords(company)} variant="outlined" />
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Job Search Status</Typography>
                    <Chip 
                      label={profile?.jobSearchStatus?.replace('_', ' ')
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')} 
                      color="primary" 
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Account Statistics
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="h4" color="primary">
                    {userData?.completedInterviews || 0}
                  </Typography>
                  <Typography variant="body1">Completed Interviews</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="h4" color="primary">
                    {userData?.assessmentScore || 0}%
                  </Typography>
                  <Typography variant="body1">Assessment Score</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper sx={{ p: 2, textAlign: 'center' }}>
                  <Typography variant="h4" color="primary">
                    {userData?.learningProgress || 0}%
                  </Typography>
                  <Typography variant="body1">Learning Progress</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper 
                  sx={{ p: 2, textAlign: 'center', cursor: 'pointer' }}
                  onClick={handleStrengthClick}
                >
                  <Typography variant="h4" color="primary">
                    {profile?.profileStrength || 0}%
                  </Typography>
                  <Typography variant="body1">Profile Strength</Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <ProfileStrengthDialog />
      <SkillDialog />
    </Container>
  );
}
