import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Box,
  Divider,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  Dashboard,
  Person,
  School,
  Work,
  Assignment,
  Forum,
  VideoCall,
  Assessment,
  TrackChanges,
  AdminPanelSettings,
} from "@mui/icons-material";
import { toggleSidebar } from '../library/store/sidebar';

const DRAWER_WIDTH = 240;
const MINI_DRAWER_WIDTH = 65;

const ROUTES_WITHOUT_SIDEBAR = [
  '/questionnaire',
  '/login',
  '/register',
  '/forgot-password'
];

const getIcon = (path) => {
  switch (path) {
    case '/admin':
      return <AdminPanelSettings />;
    case '/dashboard':
      return <Dashboard />;
    case '/profile':
      return <Person />;
    case '/learning':
      return <School />;
    case '/jobs':
      return <Work />;
    case '/applications':
      return <TrackChanges />;
    case '/resume':
      return <Assignment />;
    case '/interviews':
      return <VideoCall />;
    case '/community':
      return <Forum />;
    case '/assessments':
      return <Assessment />;
    default:
      return null;
  }
};

export default function SideBar() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.sidebar.value.isOpen);
  const userData = useSelector((state) => {
    const authData = state?.authentication?.value;
    return authData?.loginData || authData?.user || {};
  });
  const isAdmin = localStorage.getItem('isAdmin') === 'true' || userData?.role === 'admin';
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    if (ROUTES_WITHOUT_SIDEBAR.some(route => location.pathname.includes(route))) {
      dispatch(toggleSidebar(false));
    }
  }, [location.pathname, dispatch]);

  if (ROUTES_WITHOUT_SIDEBAR.some(route => location.pathname.includes(route))) {
    return null;
  }

  let menuItems = [];
  
  if (isAdmin) {
    menuItems.push({ text: 'Admin Dashboard', path: '/admin' });
  }
  
  menuItems = [
    ...menuItems,
    { text: 'Dashboard', path: '/dashboard' },
    { text: 'Profile', path: '/profile' },
    { text: 'Learning Modules', path: '/learning' },
    { text: 'Job Search', path: '/jobs' },
    { text: 'Application Tracker', path: '/applications' },
    { text: 'Resume Builder', path: '/resume' },
    { text: 'Mock Interviews', path: '/interviews' },
    { text: 'Community', path: '/community' },
    { text: 'Assessments', path: '/assessments' },
  ];

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      sx={{
        width: isOpen ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isOpen ? DRAWER_WIDTH : MINI_DRAWER_WIDTH,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          boxSizing: 'border-box',
          border: 'none',
          backgroundColor: theme.palette.background.paper,
          overflowX: 'hidden',
          position: 'relative',
        },
      }}
    >
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        pt: 8,
      }}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: isOpen ? 'flex-start' : 'center',
          p: 2,
          gap: isOpen ? 2 : 0,
          minHeight: 80,
        }}>
          <Avatar
            sx={{
              width: isOpen ? 60 : 40,
              height: isOpen ? 60 : 40,
              bgcolor: 'primary.dark',
              transition: theme.transitions.create(['width', 'height'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
          >
            {(userData?.name?.[0] || userData?.email?.[0] || 'A').toUpperCase()}
          </Avatar>
          {isOpen && (
            <Box sx={{ overflow: 'hidden' }}>
              <Typography variant="subtitle1" noWrap sx={{ color: '#fff' }}>
                {userData?.name || userData?.email || 'Administrator'}
              </Typography>
              {isAdmin && (
                <Typography variant="caption" sx={{ color: 'primary.main' }}>
                  Administrator
                </Typography>
              )}
            </Box>
          )}
        </Box>

        <Divider sx={{ my: 1, borderColor: 'rgba(255, 255, 255, 0.12)' }} />

        <List sx={{ flexGrow: 1, px: isOpen ? 1 : 0.5, overflowY: 'auto' }}>
          {menuItems.map((item) => (
            <ListItem
              component={Link}
              to={item.path}
              key={item.text}
              selected={location.pathname === item.path}
              sx={{
                minHeight: 48,
                display: 'flex',
                justifyContent: 'center',
                px: isOpen ? 2 : 1,
                py: 1,
                borderRadius: 1,
                mb: 0.5,
                color: theme.palette.text.primary,
                transition: theme.transitions.create('background-color'),
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
                '&.Mui-selected': {
                  backgroundColor: theme.palette.action.selected,
                  '&:hover': {
                    backgroundColor: theme.palette.action.selected,
                  }
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',

                  color: location.pathname === item.path ? theme.palette.primary.main : theme.palette.text.secondary,
                }}
              >
                {getIcon(item.path)}
              </ListItemIcon>
              {isOpen && <ListItemText primary={item.text} sx={{ opacity: isOpen ? 1 : 0, transition: 'opacity 0.3s' }} />}
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
}
