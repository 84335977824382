import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Snackbar, 
  Alert,
  CircularProgress,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Autocomplete,
  Switch,
  FormControlLabel,
} from '@mui/material';
import DataTable from './common/DataTable';
import { jobsApi } from '../../../api/jobsApi';
import { companyApi } from '../../../api/companyApi';
import AddIcon from '@mui/icons-material/Add';

const workTypeOptions = ['Remote', 'Hybrid', 'On-site'];
const experienceLevelOptions = ['Entry', 'Mid-Level', 'Senior', 'Executive'];

export default function JobPostingManagement() {
  const [jobs, setJobs] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    companyId: '',
    companyName: '',
    location: '',
    salary: {
      min: 0,
      max: 0,
      currency: 'USD'
    },
    workType: 'On-site',
    experienceLevel: 'Entry',
    requiredSkills: [],
    preferredSkills: [],
    responsibilities: [],
    benefits: [],
    applicationDeadline: '',
    postedDate: new Date().toISOString().split('T')[0],
    active: true
  });
  const [notification, setNotification] = useState({ 
    open: false, 
    message: '', 
    severity: 'success' 
  });
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    fetchJobs();
    fetchCompanies();
  }, []);

  const fetchJobs = async () => {
    try {
      setLoading(true);
      const response = await jobsApi.getAllJobs();
      const jobsData = Array.isArray(response.data) ? response.data : 
                      (response.data?.data ? response.data.data : []);
      setJobs(jobsData);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setJobs([]);
      showNotification('Failed to fetch jobs', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await companyApi.getAllCompanies();
      setCompanies(Array.isArray(response.data) ? response.data : 
                  (response.data?.data ? response.data.data : []));
    } catch (error) {
      console.error('Error fetching companies:', error);
      setCompanies([]);
    }
  };

  const columns = [
    { id: 'title', label: 'Title' },
    { id: 'companyName', label: 'Company' },
    { id: 'location', label: 'Location' },
    { 
      id: 'workType', 
      label: 'Work Type',
      render: (row) => (
        <Chip 
          label={row.workType} 
          color={row.workType === 'Remote' ? 'success' : row.workType === 'Hybrid' ? 'warning' : 'info'}
          size="small"
        />
      )
    },
    { 
      id: 'experienceLevel', 
      label: 'Experience',
      render: (row) => row.experienceLevel
    },
    { 
      id: 'postedDate', 
      label: 'Posted Date',
      render: (row) => new Date(row.postedDate).toLocaleDateString()
    },
    { 
      id: 'active', 
      label: 'Status',
      render: (row) => (
        <Chip 
          label={row.active ? 'Active' : 'Inactive'} 
          color={row.active ? 'success' : 'default'}
          size="small"
        />
      )
    },
  ];

  const handleEdit = (job) => {
    setSelectedJob(job);
    setFormData({
      ...job,
      salary: job.salary || { min: 0, max: 0, currency: 'USD' },
      requiredSkills: job.requiredSkills || [],
      preferredSkills: job.preferredSkills || [],
      responsibilities: job.responsibilities || [],
      benefits: job.benefits || [],
    });
    setIsCreating(false);
    setOpenDialog(true);
  };

  const handleCreate = () => {
    setSelectedJob(null);
    setFormData({
      title: '',
      description: '',
      companyId: '',
      companyName: '',
      location: '',
      salary: {
        min: 0,
        max: 0,
        currency: 'USD'
      },
      workType: 'On-site',
      experienceLevel: 'Entry',
      requiredSkills: [],
      preferredSkills: [],
      responsibilities: [],
      benefits: [],
      applicationDeadline: '',
      postedDate: new Date().toISOString().split('T')[0],
      active: true
    });
    setIsCreating(true);
    setOpenDialog(true);
  };

  const handleDelete = async (job) => {
    if (window.confirm('Are you sure you want to delete this job posting?')) {
      try {
        await jobsApi.deleteJob(job._id);
        showNotification('Job posting deleted successfully', 'success');
        fetchJobs();
      } catch (error) {
        console.error('Error deleting job posting:', error);
        showNotification('Failed to delete job posting', 'error');
      }
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      
      if (formData.companyId && !formData.companyName && companies.length > 0) {
        const company = companies.find(c => c._id === formData.companyId);
        if (company) {
          formData.companyName = company.name;
        }
      }
      
      const jobData = {
        ...formData,
        active: formData.active !== undefined ? formData.active : formData.isActive,
        isActive: formData.active !== undefined ? formData.active : formData.isActive,
        type: formData.type || formData.workType,
        workType: formData.workType || formData.type,
        requirements: formData.requirements || formData.requiredSkills,
        requiredSkills: formData.requiredSkills || formData.requirements
      };
      
      if (isCreating) {
        await jobsApi.createJob(jobData);
        showNotification('Job posting created successfully', 'success');
      } else {
        await jobsApi.updateJob(selectedJob._id, jobData);
        showNotification('Job posting updated successfully', 'success');
      }
      setOpenDialog(false);
      fetchJobs();
    } catch (error) {
      console.error('Error saving job posting:', error);
      showNotification('Failed to save job posting', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    
    if (name === 'active') {
      setFormData({ ...formData, active: checked });
    } else if (name.startsWith('salary.')) {
      const salaryField = name.split('.')[1];
      setFormData({
        ...formData,
        salary: {
          ...formData.salary,
          [salaryField]: salaryField === 'currency' ? value : Number(value)
        }
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleArrayChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleCompanySelect = (event, newValue) => {
    if (newValue) {
      setFormData({
        ...formData,
        companyId: newValue._id,
        companyName: newValue.name
      });
    } else {
      setFormData({
        ...formData,
        companyId: '',
        companyName: ''
      });
    }
  };

  const showNotification = (message, severity) => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Box sx={{ color: 'white' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Job Posting Management</Typography>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create Job Posting
        </Button>
      </Box>
      
      {loading && !openDialog ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataTable
          data={jobs}
          columns={columns}
          onEdit={handleEdit}
          onDelete={handleDelete}
          loading={loading}
        />
      )}

      {/* Job Form Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {isCreating ? 'Create Job Posting' : 'Edit Job Posting'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="title"
                label="Job Title"
                value={formData.title}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={companies}
                getOptionLabel={(option) => option.name}
                value={companies.find(c => c._id === formData.companyId) || null}
                onChange={handleCompanySelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Company"
                    required
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="location"
                label="Location"
                value={formData.location}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Work Type</InputLabel>
                <Select
                  name="workType"
                  value={formData.workType}
                  onChange={handleChange}
                  label="Work Type"
                >
                  {workTypeOptions.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Experience Level</InputLabel>
                <Select
                  name="experienceLevel"
                  value={formData.experienceLevel}
                  onChange={handleChange}
                  label="Experience Level"
                >
                  {experienceLevelOptions.map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={formData.active}
                    onChange={handleChange}
                    name="active"
                  />
                }
                label="Active"
                sx={{ mt: 3 }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="salary.min"
                label="Min Salary"
                type="number"
                value={formData.salary.min}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="salary.max"
                label="Max Salary"
                type="number"
                value={formData.salary.max}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Currency</InputLabel>
                <Select
                  name="salary.currency"
                  value={formData.salary.currency}
                  onChange={handleChange}
                  label="Currency"
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Job Description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.requiredSkills}
                onChange={(e, newValue) => handleArrayChange('requiredSkills', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={index}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Required Skills"
                    placeholder="Add skills"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.preferredSkills}
                onChange={(e, newValue) => handleArrayChange('preferredSkills', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={index}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Preferred Skills"
                    placeholder="Add skills"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.responsibilities}
                onChange={(e, newValue) => handleArrayChange('responsibilities', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={index}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsibilities"
                    placeholder="Add responsibilities"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.benefits}
                onChange={(e, newValue) => handleArrayChange('benefits', newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={index}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Benefits"
                    placeholder="Add benefits"
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="applicationDeadline"
                label="Application Deadline"
                type="date"
                value={formData.applicationDeadline}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="postedDate"
                label="Posted Date"
                type="date"
                value={formData.postedDate}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleSave} 
            variant="contained" 
            color="primary"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
} 