import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  Avatar
} from '@mui/material';
import { VideoCall, Code, Psychology, Send, ChatBubbleOutline } from '@mui/icons-material';

export default function MockInterviews() {
  const [interviewDialogOpen, setInterviewDialogOpen] = useState(false);
  const [interviewType, setInterviewType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [interviewSummary, setSummaryDialogOpen] = useState(false);

  const handleOpenInterview = (type) => {
    setInterviewType(type);
    setMessages([]);
    setInterviewStarted(false);
    setInterviewDialogOpen(true);
  };

  const handleCloseInterview = () => {
    setInterviewDialogOpen(false);
    setInterviewType('');
    setMessages([]);
    setUserInput('');
    setInterviewStarted(false);
  };

  const startInterview = () => {
    setIsLoading(true);
    setTimeout(() => {
      let initialMessage = '';
      
      switch(interviewType) {
        case 'technical':
          initialMessage = "Hello! I'm your technical interviewer today. Let's start with an algorithm problem. Given an array of integers, return indices of the two numbers such that they add up to a specific target. How would you approach this?";
          break;
        case 'behavioral':
          initialMessage = "Hi there! I'm your behavioral interviewer today. Let's start with a common question: Could you tell me about a time when you faced a significant challenge at work and how you overcame it?";
          break;
        case 'system':
          initialMessage = "Welcome to your system design interview. Today I'd like you to design a URL shortening service like TinyURL. How would you approach this problem?";
          break;
        default:
          initialMessage = "Hello! I'm your interviewer today. What kind of role are you preparing for?";
      }
      
      setMessages([
        { sender: 'ai', text: initialMessage }
      ]);
      setIsLoading(false);
      setInterviewStarted(true);
    }, 1500);
  };

  const handleSendMessage = () => {
    if (!userInput.trim()) return;
    
    // Add user message
    const newMessages = [...messages, { sender: 'user', text: userInput }];
    setMessages(newMessages);
    setUserInput('');
    setIsLoading(true);
    
    // Simulate AI response
    setTimeout(() => {
      let aiResponse = '';
      
      // Simple response logic based on interview type and context
      if (interviewType === 'technical') {
        aiResponse = "That's a good approach. What would be the time complexity of your solution? Can you think of any edge cases?";
      } else if (interviewType === 'behavioral') {
        aiResponse = "Thank you for sharing that experience. How did this situation change your approach to similar challenges in the future?";
      } else if (interviewType === 'system') {
        aiResponse = "Good start. Let's delve deeper into the database design. How would you handle the storage requirements for this service?";
      } else {
        aiResponse = "That's interesting. Could you elaborate more on that point?";
      }
      
      setMessages([...newMessages, { sender: 'ai', text: aiResponse }]);
      setIsLoading(false);
    }, 2000);
  };

  const handleEndInterview = () => {
    setSummaryDialogOpen(true);
  };

  const InterviewDialog = () => (
    <Dialog
      open={interviewDialogOpen}
      onClose={handleCloseInterview}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {interviewStarted 
          ? `${interviewType.charAt(0).toUpperCase() + interviewType.slice(1)} Interview` 
          : 'Start Interview'}
      </DialogTitle>
      <DialogContent>
        {!interviewStarted ? (
          <Box sx={{ py: 2 }}>
            <Typography variant="h6" gutterBottom>
              {interviewType === 'technical' && 'Technical Interview'}
              {interviewType === 'behavioral' && 'Behavioral Interview'}
              {interviewType === 'system' && 'System Design Interview'}
            </Typography>
            <Typography variant="body1" paragraph>
              {interviewType === 'technical' && 'This interview will focus on coding problems, algorithms, and data structures.'}
              {interviewType === 'behavioral' && 'This interview will focus on your past experiences and how you handle various workplace situations.'}
              {interviewType === 'system' && 'This interview will focus on system architecture, design patterns, and scalability.'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Our AI interviewer will ask you questions and provide feedback. 
              Your responses will be evaluated for both content and communication skills.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '60vh' }}>
            <Paper 
              variant="outlined" 
              sx={{ 
                p: 2, 
                flexGrow: 1, 
                mb: 2, 
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <List>
                {messages.map((message, index) => (
                  <ListItem 
                    key={index} 
                    alignItems="flex-start"
                    sx={{ 
                      justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                      mb: 1
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
                        alignItems: 'flex-start',
                        maxWidth: '80%'
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: message.sender === 'user' ? 'primary.main' : 'secondary.main',
                          width: 32,
                          height: 32,
                          mx: 1
                        }}
                      >
                        {message.sender === 'user' ? 'U' : 'AI'}
                      </Avatar>
                      <Paper 
                        elevation={1} 
                        sx={{ 
                          p: 2, 
                          bgcolor: message.sender === 'user' ? 'primary.light' : 'grey.100',
                          borderRadius: 2
                        }}
                      >
                        <Typography variant="body1">{message.text}</Typography>
                      </Paper>
                    </Box>
                  </ListItem>
                ))}
                {isLoading && (
                  <ListItem>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', ml: 5 }}>
                      <CircularProgress size={20} />
                    </Box>
                  </ListItem>
                )}
              </List>
            </Paper>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                placeholder="Type your response..."
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                disabled={isLoading}
                multiline
                maxRows={3}
              />
              <Button 
                variant="contained" 
                color="primary" 
                endIcon={<Send />}
                onClick={handleSendMessage}
                disabled={isLoading || !userInput.trim()}
                sx={{ ml: 1, height: '56px' }}
              >
                Send
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {!interviewStarted ? (
          <>
            <Button onClick={handleCloseInterview}>Cancel</Button>
            <Button 
              variant="contained" 
              onClick={startInterview}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Start Interview'}
            </Button>
          </>
        ) : (
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleEndInterview}
          >
            End Interview
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  const SummaryDialog = () => (
    <Dialog
      open={interviewSummary}
      onClose={() => setSummaryDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Interview Summary</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>Overall Assessment</Typography>
        <Typography variant="body1" paragraph>
          You demonstrated good communication skills and technical understanding during this mock interview.
        </Typography>
        
        <Typography variant="h6" gutterBottom>Strengths</Typography>
        <Typography variant="body1" component="ul">
          <li>Clear articulation of problems and solutions</li>
          <li>Logical approach to technical challenges</li>
          <li>Good engagement with the interviewer</li>
        </Typography>
        
        <Typography variant="h6" gutterBottom>Areas for Improvement</Typography>
        <Typography variant="body1" component="ul">
          <li>Consider discussing time and space complexity earlier</li>
          <li>Practice more specific examples in your responses</li>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setSummaryDialogOpen(false);
          handleCloseInterview();
        }}>Close</Button>
        <Button 
          variant="contained"
          onClick={() => {
            setSummaryDialogOpen(false);
            handleCloseInterview();
          }}
        >
          Save & Exit
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Mock Interviews
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box sx={{ mb: 2 }}>
                <VideoCall sx={{ fontSize: 40, color: 'primary.main' }} />
              </Box>
              <Typography variant="h6" gutterBottom>
                Technical Interview
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Practice coding problems and system design
              </Typography>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => handleOpenInterview('technical')}
                startIcon={<ChatBubbleOutline />}
              >
                Start Interview
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box sx={{ mb: 2 }}>
                <Psychology sx={{ fontSize: 40, color: 'primary.main' }} />
              </Box>
              <Typography variant="h6" gutterBottom>
                Behavioral Interview
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Practice STAR method responses
              </Typography>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => handleOpenInterview('behavioral')}
                startIcon={<ChatBubbleOutline />}
              >
                Start Interview
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box sx={{ mb: 2 }}>
                <Code sx={{ fontSize: 40, color: 'primary.main' }} />
              </Box>
              <Typography variant="h6" gutterBottom>
                System Design
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Practice architecture and design problems
              </Typography>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => handleOpenInterview('system')}
                startIcon={<ChatBubbleOutline />}
              >
                Start Interview
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      <InterviewDialog />
      <SummaryDialog />
    </Container>
  );
} 