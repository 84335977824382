import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';
import AdminRoute from '../components/AdminRoute';
import { publicRoutes, privateRoutes, adminRoutes } from './routeConfig';

export default function Routes() {
  return (
    <Switch>
      {/* Public Routes */}
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}

      {/* Private Routes */}
      {privateRoutes.map((route) => (
        <PrivateRoute
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}

      {/* Admin Routes */}
      {adminRoutes.map((route) => (
        <AdminRoute
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}

      {/* Default redirect */}
      <Redirect from="*" to="/login" />
    </Switch>
  );
} 