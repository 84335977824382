import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Layout from "./components/layout";
import Routes from "./routes";
import ToastManager from "./components/toast";
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { checkAuthStatus } from './library/store/authentication';
import { profileApi } from './api/profileApi';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Check auth status once on app load
    dispatch(checkAuthStatus());
  }, [dispatch]);

  const abortController = new AbortController();
// check if user has completed profile
  useEffect(() => {
    const fetchProfile = async () => {
      
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData?._id) {
        try {
          const profileResponse = await profileApi.getProfile(userData._id, abortController.signal);
          console.log('profileResponse', profileResponse);
        } catch (error) {
          if (!abortController.signal.aborted) {
            console.error('Error fetching profile:', error);
          }
        }
      }
    };

    fetchProfile();

    return () => {
      abortController.abort();
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Analytics></Analytics>
      <SpeedInsights></SpeedInsights>
      <Router>
        <Layout>
          <Routes />
        </Layout>
        <ToastManager />
      </Router>
    </ThemeProvider>
  );
}

export default App;