import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isLogged, loginData } = useSelector(state => state.authentication.value);
  const isAdmin = loginData?.role === 'admin';

  return (
    <Route
      {...rest}
      render={props =>
        isLogged && isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: isLogged ? "/dashboard" : "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute; 