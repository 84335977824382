import React from "react";
import SideBar from "./sidebar";
import { Box } from "@mui/material";
import { useLocation } from 'react-router-dom';

const publicPaths = ['/login', '/register', '/forgot-password'];

export default function Layout({ children }) {
  const location = useLocation();
  const isPublicPath = publicPaths.includes(location.pathname);

  return (
    <Box 
      sx={{ 
        display: "flex",
        width: "100%",
        minHeight: "100vh",
      }}
    >
      {!isPublicPath && <SideBar />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: isPublicPath ? 0 : 3,
          width: "100%",
          minHeight: "100vh",
          bgcolor: "background.default",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
