import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingScreen from './LoadingScreen';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLogged, loginData, loading } = useSelector(state => state.authentication.value);
  const location = useLocation();
  
  if (loading) {
    return <LoadingScreen />;
  }

  if (!isLogged) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )}
      />
    );
  }

  // Special handling for profile setup
  if (location.pathname !== '/profile-setup' && 
      loginData && 
      loginData.profileCompleted === false) {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: "/profile-setup"
            }}
          />
        )}
      />
    );
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}; 