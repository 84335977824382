import DashboardPage from "../containers/dashboard";
import LoginPage from "../containers/login";
import RegisterPage from "../containers/register";
import ForgotpasswordPage from "../containers/forgotpassword";
import ChangepasswordPage from "../containers/changepassword";
import UserProfilePage from "../containers/userprofile";
import LearningModules from "../containers/learning";
import LearningModule from "../containers/learningmodule";
import ApplicationTracker from "../containers/applications/ApplicationTracker";
import Questionnaire from "../containers/profilesetup/Questionnaire";
import JobSearch from "../containers/jobs/JobSearch";
import ResumeBuilder from "../containers/resume/ResumeBuilder";
import MockInterviews from "../containers/interviews/MockInterviews";
import AdminDashboard from "../containers/admin/AdminDashboard";

export const publicRoutes = [
  {
    path: "/",
    component: LoginPage,
    exact: true,
  },
  {
    path: "/login",
    component: LoginPage,
    exact: true,
  },
  {
    path: "/register",
    component: RegisterPage,
    exact: true,
  },
  {
    path: "/forgot-password",
    component: ForgotpasswordPage,
    exact: true,
  },
];

export const privateRoutes = [
  {
    path: "/dashboard",
    component: DashboardPage,
    exact: true,
  },
  {
    path: "/profile",
    component: UserProfilePage,
    exact: true,
  },
  {
    path: "/profile-setup",
    component: Questionnaire,
    exact: true,
  },
  {
    path: "/learning",
    component: LearningModules,
    exact: true,
  },
  {
    path: "/learning/:moduleId",
    component: LearningModule,
    exact: true,
  },
  {
    path: "/jobs",
    component: JobSearch,
    exact: true,
  },
  {
    path: "/applications",
    component: ApplicationTracker,
    exact: true,
  },
  {
    path: "/resume",
    component: ResumeBuilder,
    exact: true,
  },
  {
    path: "/interviews",
    component: MockInterviews,
    exact: true,
  },
  {
    path: "/change-password",
    component: ChangepasswordPage,
    exact: true,
  },
];

export const adminRoutes = [
  {
    path: "/admin",
    component: AdminDashboard,
    exact: true,
  },
]; 