import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import DataTable from './common/DataTable';
import FormDialog from './common/FormDialog';
import { userApi } from '../../../api/userApi';

export default function UserManagement() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await userApi.getUsers();
      setUsers(response || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { 
      id: 'createdAt', 
      label: 'Created',
      render: (row) => new Date(row.createdAt).toLocaleDateString()
    },
    {
      id: 'profileCompleted',
      label: 'Profile Status',
      render: (row) => row.profileCompleted ? 'Complete' : 'Incomplete'
    }
  ];

  const handleEdit = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await userApi.deleteUser(userId);
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  return (
    <Box sx={{ color: 'white' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>User Management</Typography>
      
      <DataTable
        data={users}
        columns={columns}
        onEdit={handleEdit}
        onDelete={handleDelete}
        loading={loading}
      />

      <FormDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title="Edit User"
      >
        {/* Add user edit form here */}
      </FormDialog>
    </Box>
  );
} 