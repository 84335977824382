import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Snackbar, 
  Alert,
  CircularProgress
} from '@mui/material';
import DataTable from './common/DataTable';
import FormDialog from './common/FormDialog';
import ProfileForm from './ProfileForm';
import { profileApi } from '../../../api/profileApi';
import AddIcon from '@mui/icons-material/Add';

export default function ProfileManagement() {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      setLoading(true);
      const response = await profileApi.getAllProfiles();
      setProfiles(response.data || []);
    } catch (error) {
      console.error('Error fetching profiles:', error);
      setProfiles([]);
      showNotification('Failed to fetch profiles', 'error');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { 
      id: 'userId', 
      label: 'User ID',
      render: (row) => {
        if (typeof row.userId === 'object' && row.userId.$oid) {
          return row.userId.$oid;
        }
        return String(row.userId).substring(0, 8) + '...';
      }
    },
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { 
      id: 'skills', 
      label: 'Skills',
      render: (row) => {
        const skills = row.skills || [];
        return skills.length > 0 
          ? skills.slice(0, 3).map(s => s.name || s).join(', ') + (skills.length > 3 ? '...' : '')
          : 'No skills';
      }
    },
    {
      id: 'profileStrength',
      label: 'Profile Strength',
      render: (row) => `${row.profileStrength || 0}%`
    },
    {
      id: 'profileCompleted',
      label: 'Status',
      render: (row) => row.profileCompleted ? 'Complete' : 'Incomplete'
    }
  ];

  const handleEdit = (profile) => {
    setSelectedProfile(profile);
    setIsCreating(false);
    setOpenDialog(true);
  };

  const handleCreate = () => {
    setSelectedProfile(null);
    setIsCreating(true);
    setOpenDialog(true);
  };

  const handleDelete = async (profile) => {
    if (window.confirm('Are you sure you want to delete this profile?')) {
      try {
        const userId = profile.userId?.$oid || profile.userId;
        await profileApi.deleteProfile(userId);
        showNotification('Profile deleted successfully', 'success');
        fetchProfiles();
      } catch (error) {
        console.error('Error deleting profile:', error);
        showNotification('Failed to delete profile', 'error');
      }
    }
  };

  const handleSave = async (profileData) => {
    try {
      setLoading(true);
      if (isCreating) {
        // Create new profile
        await profileApi.createProfile(profileData.userId, profileData);
        showNotification('Profile created successfully', 'success');
      } else {
        // Update existing profile
        const userId = selectedProfile.userId?.$oid || selectedProfile.userId;
        await profileApi.updateProfile(userId, profileData);
        showNotification('Profile updated successfully', 'success');
      }
      setOpenDialog(false);
      fetchProfiles();
    } catch (error) {
      console.error('Error saving profile:', error);
      showNotification('Failed to save profile', 'error');
    } finally {
      setLoading(false);
    }
  };

  const showNotification = (message, severity) => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Box sx={{ color: 'white' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">Profile Management</Typography>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={handleCreate}
        >
          Create Profile
        </Button>
      </Box>
      
      {loading && !openDialog ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataTable
          data={profiles}
          columns={columns}
          onEdit={handleEdit}
          onDelete={handleDelete}
          loading={loading}
        />
      )}

      <FormDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={isCreating ? "Create Profile" : "Edit Profile"}
        fullWidth
        maxWidth="md"
      >
        <ProfileForm 
          profile={selectedProfile} 
          onSave={handleSave}
          isCreating={isCreating}
          loading={loading}
        />
      </FormDialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
} 