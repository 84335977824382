import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
} from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import CompanyForm from './CompanyForm';
import { companyApi } from '../../../api/companyApi';

export default function CompanyManagement() {
  const [companies, setCompanies] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await companyApi.getCompanies();
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleEdit = (company) => {
    setSelectedCompany(company);
    setOpenDialog(true);
  };

  const handleDelete = async (companyId) => {
    try {
      await companyApi.deleteCompany(companyId);
      fetchCompanies();
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  const handleSubmit = async (companyData) => {
    try {
      if (selectedCompany) {
        await companyApi.updateCompany(selectedCompany._id, companyData);
      } else {
        await companyApi.createCompany(companyData);
      }
      setOpenDialog(false);
      setSelectedCompany(null);
      fetchCompanies();
    } catch (error) {
      console.error('Error saving company:', error);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Add />}
        onClick={() => setOpenDialog(true)}
        sx={{ mb: 2 }}
      >
        Add Company
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Industry</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Active Positions</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow key={company._id}>
                <TableCell>{company.name}</TableCell>
                <TableCell>{company.industry}</TableCell>
                <TableCell>{company.size}</TableCell>
                <TableCell>{company.openPositions?.length || 0}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(company)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(company._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setSelectedCompany(null);
        }}
        maxWidth="md"
        fullWidth
      >
        <CompanyForm
          company={selectedCompany}
          onSubmit={handleSubmit}
          onCancel={() => {
            setOpenDialog(false);
            setSelectedCompany(null);
          }}
        />
      </Dialog>
    </>
  );
} 