import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userApi } from '../../api/userApi';
import axiosInstance from '../../api/axiosConfig';

const token = localStorage.getItem('token');
const userData = localStorage.getItem('userData');

const initialState = {
  value: {
    isLogged: !!token && !!userData,
    loading: false,
    error: null,
    token: token,
    loginData: userData ? JSON.parse(userData) : null,
    isNewUser: false,
    initialized: true // Set to true by default
  }
};

export const authenticateUser = createAsyncThunk(
  'authentication/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await userApi.login(credentials);
      if (response.success && response.token) {
        // Ensure user has a consistent ID field
        const userData = response.user;
        if (userData.id && !userData._id) {
          userData._id = userData.id;
        } else if (userData._id && !userData.id) {
          userData.id = userData._id;
        }
        
        localStorage.setItem('token', response.token);
        localStorage.setItem('userData', JSON.stringify(userData));
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
        return {
          token: response.token,
          user: userData
        };
      }
      return rejectWithValue('Invalid login response');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Login failed');
    }
  }
);

export const checkAuthStatus = createAsyncThunk(
  'authentication/verify',
  async (_, { getState, rejectWithValue }) => {
    const state = getState().authentication;
    const token = localStorage.getItem('token');
    
    // If no token, we're definitely not logged in
    if (!token) {
      return {
        success: false,
        user: null
      };
    }

    // If we already have valid data, don't make the API call
    if (state.value.loginData && state.value.isLogged) {
      return {
        success: true,
        user: state.value.loginData
      };
    }

    try {
      const response = await userApi.verifyToken();
      return response;
    } catch (error) {
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      return rejectWithValue('Token verification failed');
    }
  }
);

export const registerUser = createAsyncThunk(
  'authentication/register',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await userApi.createUser(userData);
      if (response.success && response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('userData', JSON.stringify(response.user));
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
        return response;
      }
      return rejectWithValue('Registration failed: Invalid response from server');
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Registration failed');
    }
  }
);

export const updateUser = createAsyncThunk(
  'authentication/update',
  async ({ userId, userData }, { getState, rejectWithValue }) => {
    try {
      const response = await userApi.updateUser(userId, userData);
      
      // Update local storage with new user data
      const currentUserData = JSON.parse(localStorage.getItem('userData') || '{}');
      const updatedUserData = { ...currentUserData, ...response.data };
      
      // Ensure consistent ID fields
      if (updatedUserData.id && !updatedUserData._id) {
        updatedUserData._id = updatedUserData.id;
      } else if (updatedUserData._id && !updatedUserData.id) {
        updatedUserData.id = updatedUserData._id;
      }
      
      localStorage.setItem('userData', JSON.stringify(updatedUserData));
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message || 'Update failed');
    }
  }
);

export const checkProfileStatus = createAsyncThunk(
  'authentication/checkProfile',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await userApi.checkUserExists(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: (state) => {
      state.value.isLogged = false;
      state.value.token = null;
      state.value.loginData = null;
      localStorage.removeItem('token');
      localStorage.removeItem('userData');
      delete axiosInstance.defaults.headers.common['Authorization'];
    },
    setLoginData: (state, action) => {
      state.value.loginData = action.payload;
      localStorage.setItem('userData', JSON.stringify(action.payload));
    },
    updateProfileStatus: (state, action) => {
      if (state.value.loginData) {
        state.value.loginData.profileCompleted = action.payload;
        localStorage.setItem('userData', JSON.stringify(state.value.loginData));
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.pending, (state) => {
        state.value.loading = true;
        state.value.error = null;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.value.loading = false;
        state.value.isLogged = true;
        state.value.token = action.payload.token;
        state.value.loginData = action.payload.user;
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('userData', JSON.stringify(action.payload.user));
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.value.loading = false;
        state.value.error = action.payload;
        state.value.isLogged = false;
        state.value.token = null;
        state.value.loginData = null;
      })
      .addCase(checkAuthStatus.pending, (state) => {
        state.value.loading = true;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.value.loading = false;
        state.value.initialized = true;
        
        if (action.payload?.success && action.payload?.user) {
          state.value.isLogged = true;
          state.value.loginData = action.payload.user;
        } else {
          state.value.isLogged = false;
          state.value.loginData = null;
          state.value.token = null;
          localStorage.removeItem('token');
          localStorage.removeItem('userData');
        }
      })
      .addCase(checkAuthStatus.rejected, (state, action) => {
        state.value.loading = false;
        state.value.isLogged = false;
        state.value.token = null;
        state.value.loginData = null;
        state.value.error = action.payload;
        localStorage.removeItem('token');
      })
      .addCase(registerUser.pending, (state) => {
        state.value.loading = true;
        state.value.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.value.loading = false;
        state.value.isLogged = true;
        state.value.token = action.payload.token;
        state.value.loginData = {
          ...action.payload.user,
          profileCompleted: false
        };
        state.value.isNewUser = true;
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('userData', JSON.stringify({
          ...action.payload.user,
          profileCompleted: false
        }));
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.value.loading = false;
        state.value.error = action.payload;
        state.value.isLogged = false;
        state.value.token = null;
        state.value.loginData = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        // Ensure consistent ID fields in the updated user data
        const updatedData = { ...action.payload };
        if (state.value.loginData) {
          if (updatedData.id && !updatedData._id) {
            updatedData._id = updatedData.id;
          } else if (updatedData._id && !updatedData.id) {
            updatedData.id = updatedData._id;
          }
          
          state.value.loginData = {
            ...state.value.loginData,
            ...updatedData
          };
        }
      });
  }
});

export const { logout, setLoginData, updateProfileStatus } = authenticationSlice.actions;
export default authenticationSlice.reducer;
