import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Box,
  Chip,
  IconButton,
} from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';

export default function CompanyForm({ company, onSubmit, onCancel }) {
  const [formData, setFormData] = useState(company || {
    name: '',
    industry: '',
    description: '',
    headquarters: '',
    size: '',
    website: '',
    logo: '',
    active: true,
    desiredTraits: {
      skills: [],
      experience: [],
      education: [],
      certifications: []
    },
    recruitingContact: {
      name: '',
      email: '',
      phone: ''
    }
  });

  const [newSkill, setNewSkill] = useState('');
  const [newExperience, setNewExperience] = useState('');
  const [newEducation, setNewEducation] = useState('');
  const [newCertification, setNewCertification] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      recruitingContact: {
        ...prev.recruitingContact,
        [name]: value
      }
    }));
  };

  const handleAddTrait = (type, value, setValue) => {
    if (value.trim()) {
      setFormData(prev => ({
        ...prev,
        desiredTraits: {
          ...prev.desiredTraits,
          [type]: [...prev.desiredTraits[type], value.trim()]
        }
      }));
      setValue('');
    }
  };

  const handleRemoveTrait = (type, index) => {
    setFormData(prev => ({
      ...prev,
      desiredTraits: {
        ...prev.desiredTraits,
        [type]: prev.desiredTraits[type].filter((_, i) => i !== index)
      }
    }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  return (
    <>
      <DialogTitle>{company ? 'Edit Company' : 'Add Company'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Company Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Industry"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Headquarters"
              name="headquarters"
              value={formData.headquarters}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Company Size"
              name="size"
              value={formData.size}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Website"
              name="website"
              value={formData.website}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Logo URL"
              name="logo"
              value={formData.logo}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Add Skill"
                value={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddTrait('skills', newSkill, setNewSkill);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => handleAddTrait('skills', newSkill, setNewSkill)}>
                      <AddIcon />
                    </IconButton>
                  ),
                }}
              />
              <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {formData.desiredTraits.skills.map((skill, index) => (
                  <Chip
                    key={index}
                    label={skill}
                    onDelete={() => handleRemoveTrait('skills', index)}
                  />
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                label="Add Experience"
                value={newExperience}
                onChange={(e) => setNewExperience(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleAddTrait('experience', newExperience, setNewExperience);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => handleAddTrait('experience', newExperience, setNewExperience)}>
                      <AddIcon />
                    </IconButton>
                  ),
                }}
              />
              <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {formData.desiredTraits.experience.map((exp, index) => (
                  <Chip
                    key={index}
                    label={exp}
                    onDelete={() => handleRemoveTrait('experience', index)}
                  />
                ))}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Recruiting Contact Name"
              name="name"
              value={formData.recruitingContact.name}
              onChange={handleContactChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Recruiting Contact Email"
              name="email"
              value={formData.recruitingContact.email}
              onChange={handleContactChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Recruiting Contact Phone"
              name="phone"
              value={formData.recruitingContact.phone}
              onChange={handleContactChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button 
          variant="contained" 
          onClick={handleSubmit}
          disabled={!formData.name || !formData.industry}
        >
          {company ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </>
  );
} 