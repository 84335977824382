import { ENDPOINTS } from '../config';
import axiosInstance from './axiosConfig';

export const companyApi = {
  getAllCompanies: async () => {
    try {
      const response = await axiosInstance.get(`${ENDPOINTS.COMPANIES.BASE}`);
      return response;
    } catch (error) {
      console.error('Error fetching companies:', error);
      throw error.response?.data || error;
    }
  },

  getCompanyById: async (companyId) => {
    try {
      if (!companyId) {
        throw new Error('Company ID is required');
      }
      const response = await axiosInstance.get(`${ENDPOINTS.COMPANIES.BASE}/${companyId}`);
      return response;
    } catch (error) {
      console.error('Error fetching company details:', error);
      throw error.response?.data || error;
    }
  },

  createCompany: async (companyData) => {
    try {
      if (!companyData) {
        throw new Error('Company data is required');
      }
      const response = await axiosInstance.post(`${ENDPOINTS.COMPANIES.BASE}`, companyData);
      return response;
    } catch (error) {
      console.error('Error creating company:', error);
      throw error.response?.data || error;
    }
  },

  updateCompany: async (companyId, companyData) => {
    try {
      if (!companyId) {
        throw new Error('Company ID is required');
      }
      const response = await axiosInstance.put(
        `${ENDPOINTS.COMPANIES.BASE}/${companyId}`,
        companyData
      );
      return response;
    } catch (error) {
      console.error('Error updating company:', error);
      throw error.response?.data || error;
    }
  },

  deleteCompany: async (companyId) => {
    try {
      if (!companyId) {
        throw new Error('Company ID is required');
      }
      const response = await axiosInstance.delete(`${ENDPOINTS.COMPANIES.BASE}/${companyId}`);
      return response;
    } catch (error) {
      console.error('Error deleting company:', error);
      throw error.response?.data || error;
    }
  },

  searchCompanies: async (searchTerm) => {
    try {
      const response = await axiosInstance.get(`${ENDPOINTS.COMPANIES.BASE}/search?q=${searchTerm}`);
      return response;
    } catch (error) {
      console.error('Error searching companies:', error);
      throw error.response?.data || error;
    }
  },

  getCompanyPositions: async (companyId) => {
    try {
      if (!companyId) {
        throw new Error('Company ID is required');
      }
      const response = await axiosInstance.get(`${ENDPOINTS.COMPANIES.BASE}/${companyId}/positions`);
      return response;
    } catch (error) {
      console.error('Error fetching company positions:', error);
      throw error.response?.data || error;
    }
  },

  addCompanyPosition: async (companyId, positionData) => {
    try {
      if (!companyId) {
        throw new Error('Company ID is required');
      }
      if (!positionData) {
        throw new Error('Position data is required');
      }
      const response = await axiosInstance.post(
        `${ENDPOINTS.COMPANIES.BASE}/${companyId}/positions`,
        positionData
      );
      return response;
    } catch (error) {
      console.error('Error adding company position:', error);
      throw error.response?.data || error;
    }
  },

  updateCompanyLogo: async (companyId, logoFile) => {
    try {
      if (!companyId) {
        throw new Error('Company ID is required');
      }
      if (!logoFile) {
        throw new Error('Logo file is required');
      }
      
      const formData = new FormData();
      formData.append('logo', logoFile);
      
      const response = await axiosInstance.post(
        `${ENDPOINTS.COMPANIES.BASE}/${companyId}/logo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return response;
    } catch (error) {
      console.error('Error updating company logo:', error);
      throw error.response?.data || error;
    }
  }
}; 