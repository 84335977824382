import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  LinearProgress,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Avatar,
  Divider,
} from "@mui/material";
import {
  Timeline,
  Assignment,
  People,
  TrendingUp,
  Work,
  LocationOn,
  CalendarToday,
} from "@mui/icons-material";
import { profileApi } from "../api/profileApi";
import { jobsApi } from "../api/jobsApi";
import { toggleSidebar } from '../library/store/sidebar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useHistory } from "react-router-dom";

export default function DashboardPage() {
  const userData = useSelector((state) => state?.authentication?.value?.loginData) || {};
  const token = useSelector((state) => state?.authentication?.value?.token);
  const dispatch = useDispatch();
  const history = useHistory();
  const sidebarOpen = useSelector((state) => state.sidebar.value.isOpen);
  
  console.log('Dashboard - userData from Redux:', userData);
  
  // Extract userId, checking both id and _id fields
  const userId = userData?._id || userData?.id;
  
  console.log('Dashboard - determined userId:', userId);

  const [profileData, setProfileData] = useState(null);
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [strengthDialog, setStrengthDialog] = useState(false);
  const [strengthDetails, setStrengthDetails] = useState({ strength: 0, improvements: [] });

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();
    
    if (token && mounted) {
      dispatch(toggleSidebar(false));
      localStorage.setItem('dashboardLoaded', 'true');
    }
    
    const fetchData = async () => {
      try {
        setLoading(true);
        if (!userId) {
          console.log('Dashboard - No userId available for data fetch');
          if (mounted) setLoading(false);
          return;
        }
        
        console.log('Dashboard - Fetching data for userId:', userId);

        // Fetch profile strength first
        const strengthResponse = await profileApi.getProfileStrength(userId);
        if (mounted && strengthResponse?.data) {
          setStrengthDetails({
            strength: strengthResponse.data.strength,
            improvements: strengthResponse.data.improvements
          });
        }

        // Then fetch profile data
        const profileResponse = await profileApi.getProfile(userId, abortController.signal);
        if (mounted && profileResponse?.data) {
          setProfileData(profileResponse.data);
          console.log('Dashboard - Profile data set:', profileResponse.data);
        } else {
          console.log('Dashboard - No profile data returned or component unmounted');
        }
        
        // Fetch recommended jobs
        try {
          const jobsResponse = await jobsApi.getRecommendedJobs(userId);
          if (mounted && jobsResponse?.data) {
            setRecommendedJobs(jobsResponse.data.jobs || []);
          }
        } catch (jobError) {
          console.error('Error fetching recommended jobs:', jobError);
        }

        setLoading(false);
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error('Error fetching dashboard data:', error);
          if (mounted) setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
      abortController.abort();
    };
  }, [userId, dispatch, token]);

  const handleStrengthClick = async () => {
    try {
      const response = await profileApi.getProfileStrength(userId);
      setStrengthDetails(response.data);
      setStrengthDialog(true);
    } catch (error) {
      console.error('Error fetching profile strength:', error);
    }
  };
  
  const handleViewJobs = () => {
    history.push('/jobs');
  };
  
  const handleViewApplications = () => {
    history.push('/applications');
  };
  
  const handleViewInterviews = () => {
    history.push('/interviews');
  };

  const ProfileStrengthDialog = () => (
    <Dialog 
      open={strengthDialog} 
      onClose={() => setStrengthDialog(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Profile Strength Analysis</DialogTitle>
      <DialogContent>
        <Box sx={{ textAlign: 'center', my: 2 }}>
          <Typography variant="h3" color="primary">
            {strengthDetails.strength}%
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Profile Strength
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Areas for Improvement
        </Typography>
        <List>
          {strengthDetails.improvements.map((improvement, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <ArrowForwardIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={improvement} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setStrengthDialog(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  const stats = [
    { 
      icon: <Timeline />, 
      title: 'Profile Strength', 
      value: `${strengthDetails.strength || 0}%`, 
      color: '#90caf9',
      onClick: handleStrengthClick
    },
    { 
      icon: <Assignment />, 
      title: 'Tasks Completed', 
      value: `${profileData?.completedTasks?.length || 0}`, 
      color: '#81c784' 
    },
    { 
      icon: <People />, 
      title: 'Network Size', 
      value: profileData?.networkConnections?.length || 0, 
      color: '#ffb74d' 
    },
    { 
      icon: <TrendingUp />, 
      title: 'Skills Gained', 
      value: profileData?.skillAchievements?.length || 0, 
      color: '#f48fb1' 
    },
  ];

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        p: { xs: 2, md: 4 },
        minHeight: '100vh',
        bgcolor: 'background.default',
      }}
    >
      <Typography 
        variant="h4" 
        sx={{ 
          mb: 4, 
          fontWeight: 600,
          fontSize: { xs: '1.5rem', md: '2rem' },
          color: '#fff',  // White text
        }}
      >
        {new Date().getHours() < 12 ? 'Good Morning' : new Date().getHours() < 18 ? 'Good Afternoon' : 'Good Evening'}, {profileData?.name.split(' ')[0] || 'Guest'}!
      </Typography>

      <Grid 
        container 
        pb={4}
        spacing={2}
        // sx={{ 
        //   mb: 4,
        //   width: '100%',
        //   margin: 0,
        // }}
      >
        {stats.map((stat) => (
          <Grid item xs={12} sm={6} md={3} key={stat.title}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 2,
                bgcolor: 'background.paper',
                border: '1px solid rgba(255, 255, 255, 0.12)',
                height: '100%',
                cursor: stat.onClick ? 'pointer' : 'default',
              }}
              onClick={stat.onClick}
            >
              <Box sx={{ 
                color: stat.color, 
                mb: 2,
                '& svg': { fontSize: 40 }
              }}>
                {stat.icon}
              </Box>
              <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                {stat.value}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {stat.title}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper 
            sx={{ 
              p: 3, 
              borderRadius: 2,
              mb: 3,
              bgcolor: 'background.paper',
              border: '1px solid rgba(255, 255, 255, 0.12)',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Your Career Journey
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>Overall Progress</Typography>
              <LinearProgress 
                variant="determinate" 
                value={strengthDetails.strength || 0} 
                sx={{ 
                  height: 8, 
                  borderRadius: 4,
                  bgcolor: 'rgba(144, 202, 249, 0.16)',
                  '& .MuiLinearProgress-bar': {
                    bgcolor: 'primary.main',
                  }
                }} 
              />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mb: 2 }}
                  onClick={() => history.push('/learning')}
                >
                  Continue Learning
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  onClick={handleViewInterviews}
                >
                  Practice Interview
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                  onClick={() => history.push('/profile')}
                >
                  Update Profile
                </Button>
              </Grid>
            </Grid>
          </Paper>
          
          {/* Recommended Jobs Section */}
          <Paper 
            sx={{ 
              p: 3, 
              borderRadius: 2,
              bgcolor: 'background.paper',
              border: '1px solid rgba(255, 255, 255, 0.12)',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">
                Recommended Jobs
              </Typography>
              <Button 
                variant="text" 
                onClick={handleViewJobs}
                sx={{ textTransform: 'none' }}
              >
                View All
              </Button>
            </Box>
            
            {loading ? (
              <LinearProgress />
            ) : recommendedJobs.length > 0 ? (
              <List sx={{ p: 0 }}>
                {recommendedJobs.slice(0, 3).map((job, index) => (
                  <React.Fragment key={job._id}>
                    <ListItem 
                      alignItems="flex-start" 
                      sx={{ 
                        p: 2, 
                        borderRadius: 1,
                        '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.05)' },
                        cursor: 'pointer',
                      }}
                      onClick={() => history.push(`/jobs/${job._id}`)}
                    >
                      <Box sx={{ display: 'flex', width: '100%' }}>
                        <Avatar 
                          src={job.companyLogo} 
                          alt={job.companyName}
                          sx={{ 
                            width: 50, 
                            height: 50, 
                            mr: 2,
                            bgcolor: job.companyLogo ? 'transparent' : 'primary.main'
                          }}
                        >
                          {!job.companyLogo && job.companyName?.charAt(0)}
                        </Avatar>
                        
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                            {job.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {job.companyName}
                          </Typography>
                          
                          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, flexWrap: 'wrap' }}>
                            {job.location && (
                              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, mb: 0.5 }}>
                                <LocationOn fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
                                <Typography variant="body2" color="text.secondary">
                                  {job.location}
                                </Typography>
                              </Box>
                            )}
                            
                            {job.workType && (
                              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, mb: 0.5 }}>
                                <Work fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
                                <Typography variant="body2" color="text.secondary">
                                  {job.workType}
                                </Typography>
                              </Box>
                            )}
                            
                            {job.postedDate && (
                              <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                                <CalendarToday fontSize="small" sx={{ mr: 0.5, color: 'text.secondary' }} />
                                <Typography variant="body2" color="text.secondary">
                                  Posted {new Date(job.postedDate).toLocaleDateString()}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                          
                          <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                            <Chip 
                              label={`${job.matchScore || 0}% Match`}
                              size="small"
                              sx={{ 
                                mr: 1,
                                bgcolor: 
                                  job.matchScore > 80 ? 'success.dark' : 
                                  job.matchScore > 60 ? 'info.dark' : 
                                  job.matchScore > 40 ? 'warning.dark' : 'error.dark',
                                color: 'white'
                              }}
                            />
                            
                            {job.matchingSkills && job.matchingSkills.slice(0, 3).map((skill, i) => (
                              <Chip 
                                key={i}
                                label={skill}
                                size="small"
                                variant="outlined"
                                sx={{ mr: 1, mb: 1 }}
                              />
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </ListItem>
                    {index < recommendedJobs.slice(0, 3).length - 1 && <Divider component="li" />}
                  </React.Fragment>
                ))}
              </List>
            ) : (
              <Box sx={{ py: 3, textAlign: 'center' }}>
                <Typography variant="body1" color="text.secondary">
                  Complete your profile to get job recommendations
                </Typography>
                <Button 
                  variant="contained" 
                  sx={{ mt: 2 }}
                  onClick={() => history.push('/profile')}
                >
                  Update Profile
                </Button>
              </Box>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              p: 3,
              height: '100%',
              borderRadius: 2,
              bgcolor: 'background.paper',
              border: '1px solid rgba(255, 255, 255, 0.12)',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Quick Actions
            </Typography>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Assignment />}
              sx={{ 
                justifyContent: 'flex-start', 
                mb: 2,
                p: 1.5,
                borderRadius: 2
              }}
              onClick={handleViewApplications}
            >
              Track Applications
            </Button>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Work />}
              sx={{ 
                justifyContent: 'flex-start', 
                mb: 2,
                p: 1.5,
                borderRadius: 2
              }}
              onClick={handleViewJobs}
            >
              Browse Jobs
            </Button>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<People />}
              sx={{ 
                justifyContent: 'flex-start', 
                mb: 2,
                p: 1.5,
                borderRadius: 2
              }}
              onClick={() => history.push('/network')}
            >
              Grow Your Network
            </Button>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Timeline />}
              sx={{ 
                justifyContent: 'flex-start', 
                p: 1.5,
                borderRadius: 2
              }}
              onClick={() => history.push('/resume')}
            >
              Update Resume
            </Button>
          </Paper>
        </Grid>
      </Grid>
      <ProfileStrengthDialog />
    </Box>
  );
}
