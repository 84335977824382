import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  Typography,
  Alert,
  CircularProgress,
} from '@mui/material';
import InterestsForm from './steps/InterestsForm';
import EducationForm from './steps/EducationForm';
import ExperienceForm from './steps/ExperienceForm';
import SkillsForm from './steps/SkillsForm';
import TargetCompaniesForm from './steps/TargetCompaniesForm';
import JobSearchPreferencesForm from './steps/JobSearchPreferencesForm';
import ProfileBasicsForm from './steps/ProfileBasicsForm';
import { useHistory } from 'react-router-dom';
import { profileApi } from '../../api/profileApi';
import { updateUser } from '../../library/store/authentication';
import { checkAuthStatus } from '../../library/store/authentication';
import { toggleSidebar } from '../../library/store/sidebar';
import { setLoginData } from '../../library/store/authentication';
import { updateProfileStatus } from '../../library/store/authentication';
import { store } from '../../library/store/store';

const steps = [
  'Interests & Hobbies',
  'Education',
  'Experience',
  'Skills',
  'Target Companies',
  'Job Search Preferences',
  'Profile Basics',
];

export default function Questionnaire() {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    interests: [],
    hobbies: [],
    education: [],
    experience: [],
    skills: [],
    targetCompanies: [],
    preferredRoles: [],
    jobSearchStatus: 'actively_looking',
    networkingPreferences: {
      openToMentoring: false,
      openToConnecting: true,
      preferredIndustries: []
    },
    name: '',
    email: '',
    phone: '',
    location: '',
    bio: ''
  });
  
  const history = useHistory();
  const dispatch = useDispatch();
  const { loginData, token } = useSelector((state) => state.authentication.value);

  useEffect(() => {
    let mounted = true;

    const checkProfileStatus = async () => {
      try {
        // Check if user is logged in with valid token and ID
        if (!token || (!loginData?._id && !loginData?.id)) {
          console.log('No valid login data found, redirecting to login');
          history.push('/login');
          return;
        }

        const userId = loginData?._id || loginData?.id;
        console.log('Using userId:', userId);

        // Get user's profile
        const profileResponse = await profileApi.getProfile(userId);
        
        console.log('profileResponse:', profileResponse);
        // Only redirect if mounted and profile is completed
        if (mounted && profileResponse?.data?.profileCompleted) {
          history.push('/dashboard');
        }

        // Force sidebar to minimize on mount
        if (mounted) {
          dispatch(toggleSidebar(false));
        }
      } catch (error) {
        console.error('Error checking profile status:', error);
        if (mounted) {
          setError('Failed to check profile status');
        }
      }
    };

    checkProfileStatus();

    return () => {
      mounted = false;
    };
  }, [token, loginData, history, dispatch]);
  
  const validateStep = useCallback((step) => {
    switch (step) {
      case 0: // Interests & Hobbies
        return formData.interests.length > 0 || formData.hobbies.length > 0;
      case 1: // Education
        return formData.education.length > 0;
      case 2: // Experience
        return formData.experience.length > 0;
      case 3: // Skills
        return formData.skills.length > 0;
      case 4: // Target Companies
        return formData.targetCompanies.length > 0;
      case 5: // Job Search Preferences
        return formData.jobSearchStatus !== '';
      case 6: // Profile Basics
        return formData.name.trim() !== '' && formData.email.trim() !== '';
      default:
        return true;
    }
  }, [formData]);

  const handleNext = useCallback(() => {
    if (validateStep(activeStep)) {
      setActiveStep((prev) => prev + 1);
    } else {
      setError('Please complete all required fields before proceeding');
    }
  }, [activeStep, validateStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      // Get the latest user data from the store
      const { loginData } = store.getState().authentication.value;
      console.log('Current loginData:', loginData);
      
      // Check for userId in either id or _id field
      const userId = loginData?._id || loginData?.id;
      
      if (!userId) {
        console.error('User ID not found in', loginData);
        throw new Error('User ID not found');
      }

      console.log('Using userId for profile creation:', userId);

      // Create profile first
      const createResult = await profileApi.createProfile(userId, {
        ...formData,
        profileCompleted: true,
      });
      
      console.log('Profile creation result:', createResult);

      // Update user data in Redux store
      await dispatch(updateUser({
        userId,
        userData: { profileCompleted: true }
      })).unwrap();

      // Update profile
      const updateResult = await profileApi.updateProfile(userId, {
        ...formData,
        profileCompleted: true,
        questionnaireCompleted: true
      });
      
      console.log('Profile update result:', updateResult);
      
      // Update profile status in Redux store
      dispatch(updateProfileStatus(true));
      
      // Finally redirect
      history.push('/dashboard');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(`Failed to update profile: ${error.message || 'Unknown error'}`);
    } finally {
      setIsSubmitting(false);
    }
  }, [formData, dispatch, history]);

  const getStepContent = useCallback((step) => {
    switch (step) {
      case 0:
        return (
          <InterestsForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 1:
        return (
          <EducationForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <ExperienceForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 3:
        return (
          <SkillsForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <TargetCompaniesForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 5:
        return (
          <JobSearchPreferencesForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 6:
        return (
          <ProfileBasicsForm
            formData={formData}
            setFormData={setFormData}
          />
        );
      default:
        return 'Unknown step';
    }
  }, [formData]);

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Let's Get to Know You
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Stepper activeStep={activeStep} sx={{ mt: 3, mb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box>
          {activeStep === steps.length ? (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5" gutterBottom>
                All steps completed!
              </Typography>
              <Button 
                onClick={handleSubmit} 
                variant="contained" 
                disabled={isSubmitting}
                sx={{ mt: 2 }}
              >
                {isSubmitting ? (
                  <>
                    <CircularProgress size={24} sx={{ mr: 1 }} />
                    Creating Profile...
                  </>
                ) : (
                  'Create Profile'
                )}
              </Button>
            </Box>
          ) : (
            <>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
} 