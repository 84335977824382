import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Chip,
  IconButton,
  CardActions,
  MenuItem,
  CircularProgress,
  Divider,
  Autocomplete,
} from '@mui/material';
import {
  Search,
  LocationOn,
  Work,
  School,
  MonetizationOn,
  Bookmark,
  BookmarkBorder,
} from '@mui/icons-material';
import { companyApi } from '../../api/companyApi';
import { applicationApi } from '../../api/applicationApi';
import { useSelector } from 'react-redux';
import {jobsApi} from '../../api/jobsApi';
const jobTypes = ['Full-time', 'Part-time', 'Contract', 'Internship'];
const experienceLevels = ['Entry', 'Mid-Level', 'Senior', 'Lead'];

export default function JobSearch() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    location: '',
    type: '',
    level: '',
    minSalary: '',
  });
  const [companies, setCompanies] = useState([]);
  const userData = useSelector((state) => state.authentication?.value?.loginData);

  useEffect(() => {
    fetchJobs();
    fetchCompanies();
  }, []);

  const fetchJobs = async () => {
    try {
      setLoading(true);
      const response = await jobsApi.getAllJobs(filters);
      if (response?.data?.success) {
        setJobs(response.data.data || []);
      } else {
        setJobs([]);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setJobs([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await companyApi.getCompanies();
      setCompanies(response.data || []);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleSearch = () => {
    fetchJobs();
  };

  const handleQuickApply = async (jobId, companyId) => {
    try {
      await applicationApi.createApplication({
        jobPostingId: jobId,
        companyId: companyId,
        userId: userData._id,
        status: 'Submitted'
      });
      // Show success message
    } catch (error) {
      console.error('Error applying to job:', error);
    }
  };

  const JobCard = ({ job }) => {
    const company = companies.find(c => c._id === job.companyId);
    
    return (
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '10px', padding: '8px' }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {company?.logo && (
              <img
                src={company.logo}
                alt={company.name}
                style={{ width: 40, height: 40, marginRight: 16 }}
              />
            )}
            <Box>
              <Typography variant="h6" gutterBottom>
                {job.title}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {company?.name}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
            <Chip
              icon={<LocationOn />}
              label={job.location}
              size="small"
            />
            <Chip
              icon={<Work />}
              label={job.type}
              size="small"
            />
            {job.salary && (
              <Chip
                icon={<MonetizationOn />}
                label={`$${job.salary.min.toLocaleString()} - $${job.salary.max.toLocaleString()}`}
                size="small"
              />
            )}
          </Box>

          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {job.description}
          </Typography>

          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {job.skills.map((skill, index) => (
              <Chip
                key={index}
                label={skill}
                size="small"
                variant="outlined"
              />
            ))}
          </Box>
        </CardContent>

        <CardActions sx={{ mt: 'auto', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            onClick={() => handleQuickApply(job._id, job.companyId)}
          >
            Quick Apply
          </Button>
          <IconButton>
            <BookmarkBorder />
          </IconButton>
        </CardActions>
      </Card>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography className="text-white" variant="h4" gutterBottom>
        Job Search
      </Typography>

      <Card sx={{ mb: 4 , borderRadius: '10px'}}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Search Jobs"
                value={filters.search}
                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                InputProps={{
                  startAdornment: <Search sx={{ mr: 1, color: 'text.secondary' }} />
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Location"
                value={filters.location}
                onChange={(e) => setFilters({ ...filters, location: e.target.value })}
                InputProps={{
                  startAdornment: <LocationOn sx={{ mr: 1, color: 'text.secondary' }} />
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                select
                fullWidth
                label="Job Type"
                value={filters.type}
                onChange={(e) => setFilters({ ...filters, type: e.target.value })}
              >
                <MenuItem value="">All Types</MenuItem>
                {jobTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                select
                fullWidth
                label="Experience Level"
                value={filters.level}
                onChange={(e) => setFilters({ ...filters, level: e.target.value })}
              >
                <MenuItem value="">All Levels</MenuItem>
                {experienceLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={1}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSearch}
                sx={{ height: '56px' }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {jobs.map((job) => (
            <Grid item xs={12} md={6} key={job._id}>
              <JobCard job={job} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
} 