import { ENDPOINTS } from '../config';
import axiosInstance from './axiosConfig';

export const profileApi = {
  getProfile: async (userId, signal) => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
      
      const response = await axiosInstance.get(`${ENDPOINTS.PROFILES.BASE}/${userId}`, { signal });
      return response.data;
    } catch (error) {
      if (error.name === 'CanceledError' || error.name === 'AbortError') {
        console.log('Profile fetch canceled');
        return { canceled: true };
      }
      console.error('Profile API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  createProfile: async (userId, profileData, signal) => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
      
      const response = await axiosInstance.post(`${ENDPOINTS.PROFILES.BASE}/${userId}`, profileData, { signal });
      return response.data;
    } catch (error) {
      if (error.name === 'CanceledError') {
        return;
      }
      console.error('Profile Creation Error:', error.response?.data || error.message);
      throw error.response?.data || error;
    }
  },

  updateProfile: async (userId, profileData, signal) => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
      
      const response = await axiosInstance.put(`${ENDPOINTS.PROFILES.BASE}/${userId}`, profileData, { signal });
      return response.data;
    } catch (error) {
      if (error.name === 'CanceledError') {
        return;
      }
      console.error('Profile Update Error:', error.response?.data || error.message);
      throw error.response?.data || error;
    }
  },

  getProfileStrength: async (userId) => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
      
      const response = await axiosInstance.get(`${ENDPOINTS.PROFILES.BASE}/${userId}/strength`);
      return response.data;
    } catch (error) {
      console.error('Profile Strength Error:', error.response?.data || error.message);
      throw error.response?.data || error;
    }
  },

  getAllProfiles: async () => {
    try {
      const response = await axiosInstance.get(`${ENDPOINTS.PROFILES.BASE}`);
      return response.data;
    } catch (error) {
      console.error('Get All Profiles Error:', error.response?.data || error.message);
      throw error.response?.data || error;
    }
  },
  
  deleteProfile: async (userId) => {
    try {
      if (!userId) {
        throw new Error('User ID is required');
      }
      
      const response = await axiosInstance.delete(`${ENDPOINTS.PROFILES.BASE}/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Profile Deletion Error:', error.response?.data || error.message);
      throw error.response?.data || error;
    }
  }
}; 