import axiosInstance from './axiosConfig';
import { ENDPOINTS } from '../config';

export const jobsApi = {
    getAllJobs: async (filters = {}) => {
        try {
            const queryParams = new URLSearchParams(filters).toString();
            const queryString = queryParams ? `?${queryParams}` : '';
            const response = await axiosInstance.get(`${ENDPOINTS.JOBS.BASE}${queryString}`);
            return response;
        } catch (error) {
            console.error('Error fetching jobs:', error);
            throw error.response?.data || error;
        }
    },

    getJobById: async (jobId) => {
        try {
            if (!jobId) {
                throw new Error('Job ID is required');
            }
            const response = await axiosInstance.get(`${ENDPOINTS.JOBS.BASE}/${jobId}`);
            return response;
        } catch (error) {
            console.error('Error fetching job details:', error);
            throw error.response?.data || error;
        }
    },

    createJob: async (jobData) => {
        try {
            if (!jobData) {
                throw new Error('Job data is required');
            }
            const response = await axiosInstance.post(`${ENDPOINTS.JOBS.BASE}`, jobData);
            return response;
        } catch (error) {
            console.error('Error creating job:', error);
            throw error.response?.data || error;
        }
    },

    updateJob: async (jobId, jobData) => {
        try {
            if (!jobId) {
                throw new Error('Job ID is required');
            }
            const response = await axiosInstance.put(`${ENDPOINTS.JOBS.BASE}/${jobId}`, jobData);
            return response;
        } catch (error) {
            console.error('Error updating job:', error);
            throw error.response?.data || error;
        }
    },

    deleteJob: async (jobId) => {
        try {
            if (!jobId) {
                throw new Error('Job ID is required');
            }
            const response = await axiosInstance.delete(`${ENDPOINTS.JOBS.BASE}/${jobId}`);
            return response;
        } catch (error) {
            console.error('Error deleting job:', error);
            throw error.response?.data || error;
        }
    },

    searchJobs: async (searchTerm) => {
        try {
            const response = await axiosInstance.get(`${ENDPOINTS.JOBS.BASE}/search?q=${searchTerm}`);
            return response;
        } catch (error) {
            console.error('Error searching jobs:', error);
            throw error.response?.data || error;
        }
    },

    getRecommendedJobs: async (userId) => {
        try {
            if (!userId) {
                throw new Error('User ID is required');
            }
            const response = await axiosInstance.get(`${ENDPOINTS.JOBS.BASE}/recommended/${userId}`);
            return response;
        } catch (error) {
            console.error('Error fetching recommended jobs:', error);
            throw error.response?.data || error;
        }
    },

    applyToJob: async (jobId, userId, applicationData) => {
        try {
            if (!jobId) {
                throw new Error('Job ID is required');
            }
            if (!userId) {
                throw new Error('User ID is required');
            }
            
            const response = await axiosInstance.post(
                `${ENDPOINTS.JOBS.BASE}/${jobId}/apply`, 
                {
                    userId,
                    ...applicationData
                }
            );
            return response;
        } catch (error) {
            console.error('Error applying to job:', error);
            throw error.response?.data || error;
        }
    },

    getJobsByCompany: async (companyId) => {
        try {
            if (!companyId) {
                throw new Error('Company ID is required');
            }
            const response = await axiosInstance.get(`${ENDPOINTS.JOBS.BASE}/company/${companyId}`);
            return response;
        } catch (error) {
            console.error('Error fetching company jobs:', error);
            throw error.response?.data || error;
        }
    }
};